import React from 'react'
import { Flex, Box } from 'reflexbox'
import { Link } from 'gatsby'
import Container from '../components/Container'
import GradientWrapper from '../components/GradientWrapper'
import Img from 'gatsby-image'
import SEO from '../components/SEO'

const UseCases = ({ data }) => {
  return (
    <>
      <SEO title="Use cases" />

      {/* Hero */}
      <Container my={[4, 5]}>
        <Box textAlign={'center'}>
          <h1>IconJar for designers</h1>
          <p className="subtitle">
            Ensure that your icon collection doesn’t block your creative
            process.
          </p>
        </Box>
      </Container>

      {/* Content */}
      <Container mb={[5, 6]} pt={3}>
        <Flex flexWrap="wrap" mb={5}>
          <Box width={[1, 1 / 2]} px={[0, 3]}>
            <h2>Grow your icon library</h2>
            <p>
              IconJar truly comes to life when you have a great icon collection.
              Looking for icons? Check out{' '}
              <Link to="./resources">Our favourite icon sets</Link>,{' '}
              <a href="https://www.iconfinder.com/discountcode/iconjar21">
                Iconfinder
              </a>
              , or <a href="https://www.ui8.net/?rel=icnjr">UI8.net</a>
            </p>
            <Flex mx="auto" width={[1]} pt={3} flexWrap={'wrap'}>
              <Box width={[1, 1 / 2]} pr={[0, 3]} mb={4}>
                <h6>Import icons or sets</h6>
                <p>
                  Click the plus icon in the top left or press{' '}
                  <code>&#8984;</code>+<code>n</code> to import individual icons
                  or entire sets.
                </p>
              </Box>
              <Box width={[1, 1 / 2]} pl={[0, 3]} mb={4}>
                <h6>Import IconJars</h6>
                <p>
                  When you have a .iconjar set, double click the file or import
                  it from within IconJar.
                </p>
              </Box>
              <Box width={[1, 1 / 2]} pr={[0, 3]} mb={4}>
                <h6>Import preferences</h6>
                <p>
                  Control how icons are imported. Configure file types, location
                  and naming, and expansion.
                </p>
              </Box>
              <Box width={[1, 1 / 2]} pl={[0, 3]} mb={4}>
                <h6>Convert icon fonts into SVGs</h6>
                <p>
                  IconJar can convert TTF (enabled by default) and OTF fonts to
                  SVGs.
                </p>
              </Box>
              <Box width={[1, 1 / 2]} mb={4}>
                <h6>Replace icons</h6>
                <p>
                  Replace one of multiple collection to keep your sets
                  up-to-date
                </p>
              </Box>
            </Flex>
          </Box>
          <Box width={[1, 1 / 2]} px={[0, 3]}>
            <Img
              fluid={data.Import.childImageSharp.fluid}
              alt="IconJar import menu"
              style={{ borderRadius: '8px' }}
            />
          </Box>
        </Flex>
      </Container>
      <GradientWrapper
        pt={[5, 6]}
        style={{
          maxWidth: '100%',
          overflowX: 'hidden',
          marginTop: '-48px'
        }}>
        <Container mb={[4, 5]}>
          <Flex flexWrap="wrap" mb={5}>
            <Box width={[1, 1 / 2]} px={[0, 3]}>
              <Img
                fluid={data.SearchScope.childImageSharp.fluid}
                alt="Search scope"
                style={{ borderRadius: '8px' }}
              />
            </Box>
            <Box width={[1, 1 / 2]} px={[0, 3]}>
              <h2>Streamline and browse your library</h2>
              <p>
                All the features you need to browse your library efficiently.
              </p>
              <Flex mx="auto" width={[1]} pt={3} flexWrap={'wrap'}>
                <Box width={[1, 1 / 2]} pr={[0, 3]} mb={4}>
                  <h6>Tag your icons</h6>
                  <p>
                    Our app already tags icons based on an icon's file name. You
                    can overwrite tags as needed.
                  </p>
                </Box>
                <Box width={[1, 1 / 2]} pl={[0, 3]} mb={4}>
                  <h6>Smart sets</h6>
                  <p>
                    Smart sets are great for bundling your favourite icons or
                    icons that share similar characteristics.
                  </p>
                </Box>
                <Box width={[1, 1 / 2]} pr={[0, 3]} mb={4}>
                  <h6>Add groups to the set list</h6>
                  <p>
                    Improve the hierarchy of your set list by nesting sets in
                    groups, multiple levels deep if needed.
                  </p>
                </Box>

                <Box width={[1, 1 / 2]} pl={[0, 3]} mb={4}>
                  <h6>Adjust the scope of our search</h6>
                  <p>
                    Search in all sets/current selection, exclude certain
                    properties and change mode.
                  </p>
                </Box>
                <Box width={[1, 1 / 2]} mb={4}>
                  <h6>Filter view by file type or license</h6>
                  <p>
                    Control how icons are imported. Configure file types,
                    location and naming, and expansion.
                  </p>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </GradientWrapper>
    </>
  )
}

export default UseCases

export const pageQuery = graphql`
  query {
    Import: file(relativePath: { eq: "features/import@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 552, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    SearchScope: file(relativePath: { eq: "features/search-scope@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 552, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
